export default function Validation(values) {
    const errors = {}
    const email_pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const regexWebUrl = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/[^\s]*)?$/;
    // const pass_pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (values.full_name === "") {
        errors.nameerr = "Full Name is required";
    }
    if (values.school_name === "") {
        errors.schoolerr = "School Name is required";
    }
    if (values.position === "") {
        errors.poerr = "Position is required";
    }
    if (values.giv_email === "") {
        errors.emailerr = "Email is required";
    }
    if (values.giv_email) {
        if (!email_pattern.test(values.giv_email)) {
            errors.emailerr = "Invalid email format";
        }
    }
    if (values.phone_number === "") {
        errors.numerr = "Phone number is required";
    }
    if (values.interest == "") {
        errors.interesterr = "Select atleast one interest";
    }

    /*****************Sign in validation************************************/
    if (values.Email === "") {
        errors.Email = "Email is required";
    }
    if (values.Email) {
        if (!email_pattern.test(values.Email)) {
            errors.Email = "Invalid email format";
        }
    }
    if (values.Password === "") {
        errors.Password = "Password is required";
    }


    /*****************Sign up validation************************************/
    if (values.Firstname === "") {
        errors.Firstname = "Firstname is required";
    }

    // if(values.BusinessName === ""){
    //     errors.BusinessName = "Businessname is required";
    // }

    // if(values.IndustryName === ""){
    //     errors.IndustryName = "Industryname is required";
    // }

    // if(values.Webaddress === ""){
    //     errors.Webaddress = "Webaddress is required";
    // }
    if(values.Webaddress){
        if (!regexWebUrl.test(values.Webaddress)) {
            errors.Webaddress = "Invalid Web Address";
        }
    }

    if (values.Lastname === "") {
        errors.Lastname = "Lastname is required";
    }

    if (values.MobileNumber === "") {
        errors.MobileNumber = "Mobile Number is required";
    }

    if (values.Email === "") {
        errors.emailerr = "Email is required";
    }
    if (values.Email) {
        if (!email_pattern.test(values.Email)) {
            errors.emailerr = "Invalid email format";
        }
    }

    if (values.CustomerType === "") {
        errors.CustomerType = "Customer Type is required";
    }

    if (values.Agency_Name === "") {
        errors.Agency_Name = "Agency_Name is required";
    }

    // if(values.PortalID === ""){
    //     errors.PortalID = "PortalID is required";
    // }

    if (values.Username === "") {
        errors.Username = "Username is required";
    }
    if (values.Password === "") {
        errors.Password = "Password is required";
    }
    if (values.new_password === "") {
        errors.new_password = "new_password is required";
    }
    if (values.UserNameOrEmail === "") {
        errors.emailerr = "Email is required";
    }
    if (values.UserNameOrEmail) {
        if (!email_pattern.test(values.UserNameOrEmail)) {
            errors.emailerr = "Invalid email format";
        }
    }
    /********************forgot password********************************* */

    /****************checkout address ************************8 */
    if (values.CustomerName === "") {
        errors.CustomerName = "CustomerName is required";
    }
    if (values.Country === "") {
        errors.Country = "Country is required";
    }
    if (values.State === "") {
        errors.State = "State is required";
    }
    if (values.City === "") {
        errors.City = "City is required";
    }
    if (values.Address1 === "") {
        errors.Address1 = "Address1 is required";
    }
    // if(values.Address2 === ""){
    //     errors.Address2 = "Address2 is required";
    // }

    // if(values.BillEmail === ""){
    //     errors.BillEmail = "email is required";
    // }
    // if(values.BillMobileNumber === ""){
    //     errors.BillMobileNumber = "mobile number is required";
    // }



    return errors;
}