import React, { useState, useEffect } from "react";
import { Footer } from "../components/Footer.js";
import { Slide_title } from "./../components/slide-title.js";
import Validation from "../components/validation.js";
import ErrorSnackbar from "../components/ErrorSnackbar.js";
import SuccessSnackbar from "../components/SuccessSnackbar.js";
import { AuthRegistration, portal_listing } from "../service/api.js";
import { useNavigate } from "react-router-dom";
import Head_CateHead from "../components/Head_CateHead.js";

export const Signup = () => {
    const navigate = useNavigate();
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [showBillingInfo, setShowBillingInfo] = useState(false);


    // State for Signup Form
    const [values, setValues] = useState({
        Firstname: "",
        Lastname: "",
        MobileNumber: "",
        Email: "",
        CustomerType: "",
        Password: "",
        BusinessName: "",
        IndustryName: "",
        Webaddress: ""
    });

    // State for Billing Information Form
    const [billingValues, setBillingValues] = useState({
        Customername: "",
        Country: "",
        State: "",
        City: "",
        Postcode: "",
        Address1: "",
        Address2: "",
        ShippingCustomername: "",
        ShippingAddress1: "",
        ShippingPostcode: "",
        ShippingCity: "",
        ShippingState: "",
        ShippingCountry: "",
        ShippingAddress2: ""
    });

    const [errors, setErrors] = useState({});
    const [portallist, setPortallist] = useState([]);
    const [showAdditionalAddress, setShowAdditionalAddress] = useState(false);

    useEffect(() => {
        const fetchPortalList = async () => {
            try {
                const response = await portal_listing();
                const extractedPortalList = extractPortalList(response.data);
                setPortallist(extractedPortalList);
            } catch (error) {
                console.error("Error fetching portal list", error);
            }
        };

        fetchPortalList();
    }, []);

    const extractPortalList = (data) => {
        const portalArray = data.Data;
        return portalArray.map((portal) => ({
            id: portal.PortalID,
            title: portal.PortalName || "Default Title",
        }));
    };

    // Handle input changes
    function handleInput(event) {
        // console.log("eveny", event)
        const { name, value } = event.target;
        if (showBillingInfo) {
            setBillingValues((prevValues) => {
                const updatedValues = { ...prevValues, [name]: value };
                console.log('Updated Billing Values:', updatedValues);
                return updatedValues;
            });
        } else {
            setValues((prevValues) => {
                const updatedValues = { ...prevValues, [name]: value };
                console.log('Updated Signup Values:', updatedValues);
                return updatedValues;
            });
        }
    }



    // Handle validation and registration
    function handleValidation(event) {
        event.preventDefault();
        if (showBillingInfo) {
            const validationErrors = validateBillingInfo(billingValues);
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length === 0) {
                AuthRegistration_api_call(billingValues, values);
            }
        } else {
            const validationErrors = Validation(values);
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length === 0) {
                setShowBillingInfo(true); // Move to billing info step
                setBillingValues((prevValues) => ({
                    ...prevValues,
                    Customername: `${values.Firstname} ${values.Lastname}`,
                }));
            }
        }
    }

    // Handle Registration API Call
    const AuthRegistration_api_call = async (billingvalues, values) => {
        try {
            const res = await AuthRegistration(billingvalues, values);
            if (res.status === 200) {
                handleExceptionSuccessMessages(res);
                setTimeout(() => {
                    navigate("/signin");
                }, 2500);
            } else {
                handleExceptionError(res.data.ErrorMessage || "An error occurred");
            }
        } catch (error) {
            handleExceptionError(error.response?.data?.ErrorMessage || "An unexpected error occurred");
        }
    };


    // Validate Billing Information
    const validateBillingInfo = (values) => {
        const validationErrors = {};

        if (!values.Customername.trim()) {
            validationErrors.Customername = "Customer Name is required";
        }
        if (!values.Country.trim()) {
            validationErrors.Country = "Country is required";
        }
        if (!values.State.trim()) {
            validationErrors.State = "State is required";
        }
        if (!values.City.trim()) {
            validationErrors.City = "City is required";
        }
        if (!values.Postcode.trim()) {
            validationErrors.Postcode = "Postcode is required";
        }
        if (!values.Address1.trim()) {
            validationErrors.Address1 = "Address 1 is required";
        }

        // Validation for shipping address fields
        if (showAdditionalAddress) {
            if (!values.ShippingCustomername.trim()) {
                validationErrors.ShippingCustomername = "Customer Name is required";
            }
            if (!values.ShippingCountry.trim()) {
                validationErrors.ShippingCountry = "Shipping Country is required";
            }
            if (!values.ShippingState.trim()) {
                validationErrors.ShippingState = "Shipping State is required";
            }
            if (!values.ShippingCity.trim()) {
                validationErrors.ShippingCity = "Shipping City is required";
            }
            if (!values.ShippingPostcode.trim()) {
                validationErrors.ShippingPostcode = "Shipping Postcode is required";
            }
            if (!values.ShippingAddress1.trim()) {
                validationErrors.ShippingAddress1 = "Shipping Address 1 is required";
            }
        }

        return validationErrors;
    };


    // Handle Checkbox Change
    const handleCheckboxChange = (event) => {
        setShowAdditionalAddress(event.target.checked);
    };

    // Error and Success Handlers
    function handleExceptionError(res) {
        setExceptionError((ExceptionError) => [
            ...ExceptionError,
            { id: Date.now(), message: res },
        ]);
    }

    function handleExceptionSuccessMessages(res) {
        setSuccessMessages((successMessages) => [
            ...successMessages,
            { id: Date.now(), message: res.data.Message },
        ]);
    }

    function clearErrors(id) {
        setExceptionError((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== id)
        );
    }

    function clearSuccess(id) {
        setSuccessMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== id)
        );
    }

    return (
        <div>
            <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
            <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            <Head_CateHead />
            <div className="container sign_container">
                <Slide_title title={showBillingInfo ? "Signup Billing Information" : "Signup"} className="signup_page_title" />
                <div className="sign_up_wrapper">
                    <form onSubmit={handleValidation}>
                        {!showBillingInfo ? (
                            <>
                                <div className="checkout_form_tel">
                                    <h3 className="required">First Name</h3>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        name="Firstname"
                                        id="Firstname"
                                        value={values.Firstname}
                                        onChange={handleInput}
                                    />
                                    {errors.Firstname && <p className="giv_error">{errors.Firstname}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Last Name</h3>
                                    <input
                                        value={values.Lastname}
                                        type="text"
                                        placeholder="Last Name"
                                        name="Lastname"
                                        id="Lastname"
                                        onChange={handleInput}
                                    />
                                    {errors.Lastname && <p className="giv_error">{errors.Lastname}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Email</h3>
                                    <input
                                        value={values.Email}
                                        placeholder="Email"
                                        type="email"
                                        name="Email"
                                        id="Email"
                                        onChange={handleInput}
                                    />
                                    {errors.emailerr && <p className="giv_error">{errors.emailerr}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Mobile Number</h3>
                                    <input
                                        value={values.MobileNumber}
                                        type="tel"
                                        name="MobileNumber"
                                        placeholder="Mobile Number"
                                        id="MobileNumber"
                                        onChange={handleInput}
                                    />
                                    {errors.MobileNumber && <p className="giv_error">{errors.MobileNumber}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Customer Type</h3>
                                    <select name="CustomerType" value={values.CustomerType} onChange={handleInput}>
                                        <option value="">Select customer type</option>
                                        <option value="2">Wholesaler</option>
                                        <option value="1">Retailer</option>
                                    </select>
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="">Business Name</h3>
                                    <input
                                        type="text"
                                        placeholder="Business Name"
                                        name="BusinessName"
                                        id="BusinessName"
                                        value={values.BusinessName}
                                        onChange={handleInput}
                                    />
                                    {errors.BusinessName && <p className="giv_error">{errors.BusinessName}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="">Industry Name</h3>
                                    <input
                                        type="text"
                                        placeholder="Industry Name"
                                        name="IndustryName"
                                        id="IndustryName"
                                        value={values.IndustryName}
                                        onChange={handleInput}
                                    />
                                    {errors.IndustryName && <p className="giv_error">{errors.IndustryName}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="">Web address</h3>
                                    <input
                                        type="text"
                                        placeholder="Web address"
                                        name="Webaddress"
                                        id="Webaddress"
                                        value={values.Webaddress}
                                        onChange={handleInput}
                                    />
                                    {errors.Webaddress && <p className="giv_error">{errors.Webaddress}</p>}
                                </div>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Password</h3>
                                    <input
                                        type="password"
                                        name="Password"
                                        placeholder="Password"
                                        id="Password"
                                        onChange={handleInput}
                                    />
                                    {errors.Password && <p className="giv_error">{errors.Password}</p>}
                                </div>
                                <button className="frm_button form_submit_form" type="submit">Next</button>
                            </>
                        ) : (
                            <>
                                <div className="checkout_form_tel">
                                    <h3 className="required">Customer Name</h3>
                                    <input
                                        type="text"
                                        placeholder="Customer Name"
                                        name="Customername"
                                        id="Customername"
                                        value={billingValues.Customername}
                                        onChange={handleInput}
                                    />
                                    {errors.Customername && (
                                        <p className="giv_error">{errors.Customername}</p>
                                    )}
                                </div>

                                <div className="checkout_form_tel d-flex">
                                    <div>
                                        <h3 className="required">Country</h3>
                                        <input
                                            value={billingValues.Country}
                                            type="text"
                                            placeholder="Country Name"
                                            name="Country"
                                            id="Country"
                                            onChange={handleInput}
                                            style={{ width: '14.01vw', marginRight: '13px' }}
                                        />
                                        {errors.Country && (
                                            <p className="giv_error">{errors.Country}</p>
                                        )}
                                    </div>
                                    <div>
                                        <h3 className="required">State</h3>
                                        <input
                                            value={billingValues.State}
                                            type="text"
                                            placeholder="State Name"
                                            name="State"
                                            id="State"
                                            onChange={handleInput}
                                            style={{ width: '14.01vw' }}
                                        />
                                        {errors.State && (
                                            <p className="giv_error">{errors.State}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="checkout_form_tel d-flex">
                                    <div>
                                        <h3 className="required">City</h3>
                                        <input
                                            value={billingValues.City}
                                            type="text"
                                            placeholder="City Name"
                                            name="City"
                                            id="City"
                                            onChange={handleInput}
                                            style={{ width: '14.01vw', marginRight: '13px' }}
                                        />
                                        {errors.City && (
                                            <p className="giv_error">{errors.City}</p>
                                        )}
                                    </div>
                                    <div className="checkout_form_tel d-flex">
                                        <div>
                                            <h3 className="required">Postcode</h3>
                                            <input
                                                value={billingValues.Postcode}
                                                type="text"
                                                placeholder="Postcode"
                                                name="Postcode"
                                                id="Postcode"
                                                onChange={handleInput}
                                                style={{ width: '14.01vw' }}
                                            />
                                            {errors.Postcode && (
                                                <p className="giv_error">{errors.Postcode}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="checkout_form_tel">
                                    <h3 className="required">Address 1</h3>
                                    <textarea
                                        name="Address1"
                                        id="Address1"
                                        rows="1"
                                        value={billingValues.Address1}
                                        onChange={handleInput}
                                    ></textarea>
                                    {errors.Address1 && (
                                        <p className="giv_error">{errors.Address1}</p>
                                    )}
                                </div>

                                <div className="checkout_form_tel">
                                    <h3>Address 2</h3>
                                    <textarea
                                        name="Address2"
                                        id="Address2"
                                        rows="1"
                                        value={billingValues.Address2}
                                        onChange={handleInput}
                                    ></textarea>
                                    {errors.Address2 && (
                                        <p className="giv_error">{errors.Address2}</p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        type="checkbox"
                                        id="shippingAddressCheckbox"
                                        checked={showAdditionalAddress}
                                        onChange={handleCheckboxChange}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <label htmlFor="shippingAddressCheckbox">
                                        Shipping address is different from billing address
                                    </label>
                                </div>

                                {showAdditionalAddress && (
                                    <>
                                        <div className="checkout_form_tel">
                                            {/* <div> */}
                                            <h3 className="required"> Customer Name</h3>
                                            <input
                                                value={billingValues.ShippingCustomername}
                                                type="text"
                                                placeholder="customer Name"
                                                name="ShippingCustomername"
                                                id="ShippingCustomername"
                                                onChange={handleInput}

                                            />
                                            {errors.ShippingCustomername && (
                                                <p className="giv_error">{errors.ShippingCustomername}</p>
                                            )}
                                            {/* </div> */}
                                        </div>
                                        <div className="checkout_form_tel d-flex">
                                            <div>
                                                <h3 className="required"> Country</h3>
                                                <input
                                                    value={billingValues.ShippingCountry}
                                                    type="text"
                                                    placeholder=" Country Name"
                                                    name="ShippingCountry"
                                                    id="ShippingCountry"
                                                    onChange={handleInput}
                                                    style={{ width: '14.01vw', marginRight: '13px' }}
                                                />
                                                {errors.ShippingCountry && (
                                                    <p className="giv_error">{errors.ShippingCountry}</p>
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="required"> State</h3>
                                                <input
                                                    value={billingValues.ShippingState}
                                                    type="text"
                                                    placeholder="State Name"
                                                    name="ShippingState"
                                                    id="ShippingState"
                                                    onChange={handleInput}
                                                    style={{ width: '14.01vw' }}
                                                />
                                                {errors.ShippingState && (
                                                    <p className="giv_error">{errors.ShippingState}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="checkout_form_tel d-flex">
                                            <div>
                                                <h3 className="required"> City</h3>
                                                <input
                                                    value={billingValues.ShippingCity}
                                                    type="text"
                                                    placeholder=" City Name"
                                                    name="ShippingCity"
                                                    id="ShippingCity"
                                                    onChange={handleInput}
                                                    style={{ width: '14.01vw', marginRight: '13px' }}
                                                />
                                                {errors.ShippingCity && (
                                                    <p className="giv_error">{errors.ShippingCity}</p>
                                                )}
                                            </div>
                                            <div className="checkout_form_tel d-flex">
                                                <div>
                                                    <h3 className="required"> Postcode</h3>
                                                    <input
                                                        value={billingValues.ShippingPostcode}
                                                        type="text"
                                                        placeholder=" Postcode"
                                                        name="ShippingPostcode"
                                                        id="ShippingPostcode"
                                                        onChange={handleInput}
                                                        style={{ width: '14.01vw' }}
                                                    />
                                                    {errors.ShippingPostcode && (
                                                        <p className="giv_error">{errors.ShippingPostcode}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="checkout_form_tel">
                                            <h3 className="required"> Address 1</h3>
                                            <textarea
                                                name="ShippingAddress1"
                                                id="ShippingAddress1"
                                                rows="1"
                                                value={billingValues.ShippingAddress1}
                                                onChange={handleInput}
                                            ></textarea>
                                            {errors.ShippingAddress1 && (
                                                <p className="giv_error">{errors.ShippingAddress1}</p>
                                            )}
                                        </div>

                                        <div className="checkout_form_tel">
                                            <h3> Address 2</h3>
                                            <textarea
                                                name="ShippingAddress2"
                                                id="ShippingAddress2"
                                                rows="1"
                                                value={billingValues.ShippingAddress2}
                                                onChange={handleInput}
                                            ></textarea>
                                            {errors.ShippingAddress2 && (
                                                <p className="giv_error">{errors.ShippingAddress2}</p>
                                            )}
                                        </div>
                                    </>
                                )}


                                <button
                                    type="button"
                                    className="frm_button form_submit_form"
                                    onClick={() => setShowBillingInfo(false)}
                                    style={{ marginRight: '10px' }}
                                >
                                    Back
                                </button>


                                <button
                                    type="submit"
                                    className="frm_button form_submit_form"
                                >
                                    Sign Up
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};
