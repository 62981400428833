import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Head_CateHead from "../components/Head_CateHead.js";
import { Footer } from "../components/Footer.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel"; 
import { get_order_detail_new, submit_review } from "../service/api.js";
import { Slide_title } from "./../components/slide-title.js";
import { artwork_post, portal_order_api } from "../service/api.js";
import pdf from "./../assets/img/pdf.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
  Link,
} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CryptoJS from 'crypto-js';


const Approve = () => {
  const { hash } = useParams();
  const [id, setId] = useState(null);
  const [sessionObj, setSessionObj] = useState({});
  const navigate = useNavigate();
  const [finishart, setfinishart] = useState(false);
  const [showArtWork, setShowArtwork] = useState(false);
  const [artIndex, setArtIndex] = useState(0);

  const portal_api = async (toSendObj) => {
    try {
      const res = await portal_order_api(toSendObj);
      if (res.status == 200) {
        setfinishart(true);
      }
    } catch (error) {
      console.error("Error fetching data Maina:", error);
      navigate("/404")
    }
  };

  useEffect(() => {
    const bytes = CryptoJS.AES.decrypt(hash, "ashwin@resolutesolutions.in");
    if(!bytes){
      navigate("/404");
      return;
    }
    const session = bytes.toString(CryptoJS.enc.Utf8);
    if (!session) {
      navigate("/404");
      return;
    }
    const parsedSession = JSON.parse(session);
    if (!parsedSession) {
      navigate("/404");
      return;
    }
    setId(parsedSession ? parsedSession?.orderId : null);
    setSessionObj(parsedSession ? parsedSession : {});
    portal_api(parsedSession);

  }, []);


  const [data, setdata] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleStarClick = (index) => {
    setRating(index + 1); // Set the rating based on the star index
  };

  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  function handleExceptionSuccessMessages(res) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: res.data.Message },
    ]);
  }
  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }

  const handleSubmit = async (orderDetailId, productId) => {
    const reviewData = {
      Rating: rating,
      Remarks: comment,
      OrderDetailsID: orderDetailId,
      ProductID: productId,
    };

    const res = await submit_review(reviewData, id);
    if (res.status === 200) {
      handleExceptionSuccessMessages(res);

      data_get(id);
    } else {
      handleExceptionError(res.response.data.ErrorMessage);
    }
    console.log("res", res);

    console.log("Review submitted:", reviewData);
    setRating(0);
    setComment("");
  };

  const [approve, setApprove] = useState("");
  const [count, setCount] = useState(null);
  const data_get = async (id) => {
    try {
      const res = await get_order_detail_new(id);
      console.log("THIS IS RESPONSE FROM PRODUCT", res);
      setdata(res.data.Data);
      let totalAmount = 0; // Total order value
      let totalCount = 0;

      res.data.Data.OrderDetails.forEach((item) => {
        let price = parseFloat(item.ProductPrice);
        let qty = item.QTY;
        let totalPriceForItem = price * qty;

        totalAmount += totalPriceForItem;
        totalCount += qty;
      });

      setCount(totalAmount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    data_get(id);
    console.log("artwork data ", data);
    if (data?.OrderArtworkAttachment?.length > 0) {
      // Access the Approve status of the first element
      const approveStatus = data.OrderArtworkAttachment[0].Approve;

      if (approveStatus === 2) {
        setApprove("Pending");
      } else if (approveStatus === 1) {
        setApprove("Approved");
      } else {
        setApprove("Reject");
      }
    }
  }, [id]);

  const [isModalOpen, setIsModalOpen] = useState({});
  const [isModalOpen2, setIsModalOpen2] = useState({});

  const toggleModal = (orderId, bool) => {
    if (bool) {
      // console.log('data'.data)
      setIsModalOpen({ id: orderId, show: bool });
      const allArt = data?.OrderCustomerAttachment?.filter(
        (art) => art.OrderDetailsID === orderId
      );
      console.log(data.OrderCustomerAttachment);
      setOpenedArtwork(allArt[0]);
      setAllArtwork([...allArt]);
      setArtIndex(0);
    } else {
      setIsModalOpen({});
      setOpenedArtwork({});
      setAllArtwork([]);
      setArtIndex(0);
    }
  };
  const toggleModal2 = (orderId, bool) => {
    if (bool) {
      setIsModalOpen2({ id: orderId, show: bool });
      const allArt = data?.OrderArtworkAttachment?.filter(
        (art) => art.OrderDetailsID === orderId
      );
      setOpenedArtwork(allArt[0]);
      setAllArtwork([...allArt]);
      setArtIndex(0);
    } else {
      setIsModalOpen2({});
      setOpenedArtwork({});
      setAllArtwork([]);
      setArtIndex(0);
    }
  };

  const [artwork, setartwork] = useState(1);

  const handelartwork = (value) => {
    setartwork(value);
  };
  // useEffect(() => { }, [artwork]);
 

  const handlePrevNext = (direction) => {
    // Logic to handle previous and next buttons
    // Update artIndex accordingly
    if (direction === "prev" && artIndex > 0) {
      setArtIndex(artIndex - 1);
    } else if (direction === "next" && artIndex < allArtwork.length - 1) {
      setArtIndex(artIndex + 1);
    }
  };
  const handleShowArtwork = (index) => {
    const pdfUrl =
      process.env.REACT_APP_API_URL + allArtwork[index]?.Attachment;

    window.open(pdfUrl, "_blank");
  };

  const artwork_api = async (artworkid, status, orderDetailsId) => {
    try {
      const res = await artwork_post(data, status, artworkid, orderDetailsId);
      if (res.status == 200) {
        setfinishart(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getApproveStatus = (approve) => {
    if (approve === 2) {
      return "Pending";
    } else if (approve === 1) {
      return "Approved";
    } else {
      return "Reject";
    }
  };

  const arr = [1, 2, 3, 4];


  const [openedArtwork, setOpenedArtwork] = useState("");
  const [allArtwork, setAllArtwork] = useState();

  const convertToString = (data) => {
    // Validate that data is an array
    if (!Array.isArray(data)) {
      throw new TypeError("Expected an array as input");
    }

    const grouped = data.reduce((acc, item) => {
      if (
        !item.name ||
        typeof item.name !== "string" ||
        item.value === null ||
        item.value === undefined ||
        item.value === ""
      ) {
        return acc; // Skip if data is not valid
      }

      const nameParts = item.name.split(" ");
      const baseName = nameParts.slice(0, 2).join(" ");

      if (!acc[baseName]) acc[baseName] = 0;
      acc[baseName] += Number(item.value); // Convert to number to ensure valid addition
      return acc;
    }, {});

    const output = Object.entries(grouped)
      .map(([key, value]) => `${key}(${value})`)
      .join(" ");

    return output;
  };


  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Head_CateHead />

      <div className="sign_in_wrapper container account">
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px", // Added margin to top and bottom
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "12px 0px", // Slightly increased padding
              borderRadius: "10px",
              maxWidth: "fit-content",
              gap: "12px", // Adjusted gap for better spacing
            }}
          >

            {
              sessionObj?.orderAction == "IN REVIEW" ? (<><CheckCircleIcon style={{ color: "green", fontSize: "24px" }} />
                <p
                  style={{
                    margin: 0,
                    color: "green",
                    fontSize: "18px",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >
                  Your order has been approved successfully.
                </p></>) : (<><CancelIcon style={{ color: "red", fontSize: "24px" }} />
                  <p
                    style={{
                      margin: 0,
                      color: "red",
                      fontSize: "18px",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Your order has been rejected.
                  </p></>)
            }
          </div>
        </div>

        <Slide_title
          title="Order Information"
          className="order_detail_page_title"
        />
        <div className="order_detail_wrapper order">
          <h3>OrderID: #{id}</h3>
          <p>Thank You for shopping with us.</p>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "33.33%" }}>
              <h4>Shipping Address</h4>
              <p>
                {data.SCustomerName}
                <br />
                {data.SAddress1} {data.SAddress2}
                <br />
                {data.SCity}
                <br />
                {data.SState}
                <br />
                {data.SCountry} - {data.SZipcode}
              </p>
            </div>
            <div style={{ width: "33.33%" }}>
              <h4>Billing Address</h4>
              <p>
                {data.BCustomerName}
                <br />
                {data.BAddress1} {data.BAddress2}
                <br />
                {data.BCity}
                <br />
                {data.BState}
                <br />
                {data.BCountry} - {data.BZipcode}
                <br />
                {data.BMobileNumber}
                <br />
                {data.BEmail}
              </p>
            </div>
            <div style={{ width: "33.33%" }}>
              <h4>Payment Method</h4>
              <p>
                Payment Method: {data.PaymentMethod}
                <br />
                Order Status: {data.Status}
                <br />
                PO Number: {data.ponumber}
              </p>
            </div>
          </div>
          <div className="cart_wrap order_detail_wrap">
            <div className="cart_list order">
              {data?.OrderDetails?.map((item) => {
                const filteredOrderSetupPrice = data.OrderSetupPrice?.filter(
                  (setp) =>
                    setp.QTY > 0 && setp.OrderDetailsID === item.OrderDetailsID
                );
                return (
                  <div className="cart_item">
                    <div className="cart_img">
                      <Link
                        target="_blank"
                        to={`/product/${item.Product.Slug}`}
                      >
                        <img
                          src={`https:///api.bebranded.com.au/${item.Product?.ProductImage}`}
                        />
                      </Link>
                    </div>
                    <div className="cart_info">
                      <div className="cart_info1">
                        <div className="cart_detail order">
                          <div className="cart_title">
                            <h3>
                              <Link
                                target="_blank"
                                style={{ color: "#252525" }}
                                to={`/product/${item.Product.Slug}`}
                              >
                                {" "}
                                {item.ProductName}
                              </Link>
                              {item.randomnumber != "" &&
                                item.randomnumber != null && (
                                  <p>
                                    {item?.randomnumber?.map(
                                      (randomnumber, i) => {
                                        return (
                                          <span className="sm-txt" key={i}>
                                            {randomnumber.name}(
                                            {randomnumber.value}){" "}
                                          </span>
                                        );
                                      }
                                    )}
                                  </p>
                                )}
                              {filteredOrderSetupPrice?.length > 0 && (
                                <p
                                  style={{ marginTop: "8px", fontSize: "1vw" }}
                                >
                                  Additional Cost:
                                </p>
                              )}
                              {filteredOrderSetupPrice?.map((order, index) => (
                                <span className="sm-txt" key={index}>
                                  {order.SetupName} ({order.QTY}){" "}
                                </span>
                              ))}
                              <br /> QTY :- {item.QTY} X ${item.ProductPrice}
                            </h3>
                            <div className="cart_color_size">
                              {/* <span><img src={fill} />Red<hr /><img src={size} />XL</span> */}
                              {item.Brief && <span>Brief: {item.Brief}</span>}
                            </div>
                          </div>
                          <div className="cart_info2">
                            {isModalOpen.id === item.OrderDetailsID && (
                              <div className="modal z-1">
                                <div className="order_box_delete">
                                  <div>
                                    <p>Artwork</p>
                                  </div>
                                  <button
                                    style={{ backgroundColor: "red" }}
                                    onClick={() =>
                                      toggleModal(item.OrderDetailsID, false)
                                    }
                                  >
                                    <CloseIcon />
                                  </button>
                                </div>
                                {allArtwork.length > 0 ? (
                                  <div className="artwork_wrapper">
                                    <div className="artwork_icons">
                                      {allArtwork.map((artwork, index) => (
                                        <img
                                          key={index}
                                          src={pdf}
                                          alt={`pdf-${index + 1}`}
                                          onClick={() =>
                                            handleShowArtwork(index)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            height: "60px",
                                          }}
                                        />
                                      ))}
                                    </div>
                                    {showArtWork && (
                                      <div className="w-100 atwork_div">
                                        <div className="d-flex pre_ifrem_nex">
                                          <iframe
                                            src={
                                              process.env.REACT_APP_API_URL +
                                              openedArtwork?.Attachment
                                            }
                                            frameBorder="0"
                                            scrolling="no"
                                            height="100%"
                                            width="95%"
                                            overflow="hidden"
                                          ></iframe>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <h2 className="text-danger">
                                    No Artwork Attechment
                                  </h2>
                                )}
                              </div>
                            )}
                            {isModalOpen2.id === item.OrderDetailsID && (
                              <div className="modal z-1">
                                <div className="order_box_delete">
                                  <div>
                                    <p>Artwork</p>
                                  </div>
                                  <button
                                    style={{ backgroundColor: "red" }}
                                    onClick={() =>
                                      toggleModal2(item.OrderDetailsID, false)
                                    }
                                  >
                                    <CloseIcon />
                                  </button>
                                </div>
                                {allArtwork.length > 0 ? (
                                  <div className="artwork_wrapper">
                                    <div className="artwork_icons">
                                      {allArtwork.map((artwork, index) => (
                                        <div className="artworks">
                                          <img
                                            key={index}
                                            src={pdf}
                                            alt={`pdf-${index + 1}`}
                                            onClick={() =>
                                              handleShowArtwork(index)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              height: "60px",
                                            }}
                                          />
                                          <p>
                                            Status :{" "}
                                            {getApproveStatus(
                                              data.OrderArtworkAttachment[index]
                                                .Approve
                                            )}
                                          </p>
                                        </div>
                                      ))}
                                    </div>

                                    {showArtWork && (
                                      <div className="w-100 atwork_div">
                                        <div className="d-flex pre_ifrem_nex">
                                          <iframe
                                            src={
                                              process.env.REACT_APP_API_URL +
                                              allArtwork[artIndex]?.Attachment
                                            }
                                            frameBorder="0"
                                            scrolling="no"
                                            height="100%"
                                            width="95%"
                                            overflow="hidden"
                                          ></iframe>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <h2 className="text-danger">
                                    No Artwork Attachment
                                  </h2>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="cart_price">
                            {/* <h5>${data.Totalprice}</h5> */}
                            <h5>
                              ${(item.ProductPrice * item.QTY).toFixed(2)}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {data.Status === "COMPLETE" &&
                        data.ReviewSubmitStatus === 0 && (
                          <>
                            {data.ProductReview.some(
                              (review) =>
                                review.OrderDetailsID === item.OrderDetailsID
                            ) ? (
                              (() => {
                                const review = data.ProductReview.find(
                                  (review) =>
                                    review.OrderDetailsID ===
                                    item.OrderDetailsID
                                );
                                const rating = parseFloat(review.Rating);

                                return (
                                  <div className="review_remarks">
                                    <h4>Review:</h4>

                                    <div className="review_box">
                                      {[...Array(5)].map((_, index) => (
                                        <img
                                          key={index}
                                          src={
                                            index < rating
                                              ? "/img/yellow_star.svg"
                                              : "/img/grey_star.svg"
                                          }
                                          width={24}
                                          alt={`star-${index}`}
                                        />
                                      ))}
                                    </div>
                                    <p style={{ marginTop: "20px" }}>
                                      {review.Remarks}
                                    </p>
                                  </div>
                                );
                              })()
                            ) : (
                              <>
                                <div className="cart_title">
                                  <h3>Please share your review</h3>
                                </div>
                                <div className="review_container">
                                  <div className="review_box">
                                    {[...Array(5)].map((_, index) => (
                                      <img
                                        key={index}
                                        src={
                                          index < rating
                                            ? "/img/yellow_star.svg"
                                            : "/img/grey_star.svg"
                                        }
                                        width={24}
                                        alt={`star-${index}`}
                                        onClick={() => handleStarClick(index)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    ))}
                                  </div>
                                  <div className="checkout_form_tel">
                                    <h3>Comment</h3>
                                    <textarea
                                      name="comment"
                                      id="comment"
                                      value={comment}
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                  <button
                                    style={{ marginTop: "20px" }}
                                    className="frm_button"
                                    onClick={() =>
                                      handleSubmit(
                                        item.OrderDetailsID,
                                        item.ProductID
                                      )
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                );
              })}
              <hr className="cart_item_space" />
            </div>
            <div
              className="cart_total order"
              style={{ marginRight: "0", width: "250px" }}
            >
              <div className="cart_title">
                <h3>Order Summary</h3>
              </div>
              <div className="cart_total_list">
                <div className="cart_count1">
                  <h3>SubTotal</h3>
                  <h4>${count && count.toFixed(2)}</h4>
                </div>
                <hr className="cart_total_hr" />
                {/* {data.OrderSetupPrice ? (
              data.OrderSetupPrice.map((item, index) => (
                <>
                  <div className="cart_count1" key={index}>
                    <h3>{item.SetupName}</h3>
                    <h4>${item.SetupPrice}</h4>
                  </div>
                  <hr className="cart_total_hr" />
                </>
              ))
            ) : (
              <></>
            )} */}
                {Number(data.CouponValue) > 0 && (
                  <>
                    <div className="cart_count1">
                      <h3>Coupon Discount : "({data.CouponName})"</h3>
                      <h4>-${data.CouponValue}</h4>
                    </div>
                    <hr className="cart_total_hr" />
                  </>
                )}
                <div className="cart_count1">
                  <h3>GST ({data.GSTText}%)</h3>
                  <h4>${data.GSTAmount}</h4>
                </div>
                <hr className="cart_total_hr" />
                <div className="cart_count1">
                  <h3>Order total</h3>
                  <h4>${data.Totalprice}</h4>
                </div>
              </div>
              {/* <p>Learn more <a href='#'>Taxes</a> and <a href="#">Shipping</a> information</p> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Approve;
